<template>
  <div class="memberCentre">
    <div class="header flex-center-between">
      <div class="back flex-center-center" @click="$router.go(-1)">
        <svg-icon icon-class="headArrow" />
      </div>
      <div class="title">会员中心</div>
      <div class="right-btn" @click="$router.push('/flowDetails')">
        <svg-icon class="rightIcon" icon-class="bill" />
      </div>
    </div>
    <div class="main">
      <Vip @showPrivilege="showPrivilege" :vipList="vipList" v-if="vipList.length > 0 && currentTab == 0" />
      <div class="privilegeBox flex-center-between">
        <div class="vipIcon vipLevel1" v-if="product.vipLevel == 1"></div>
        <div class="vipIcon vipLevel2" v-else-if="product.vipLevel == 2"></div>
        <div class="vipIcon vipLevel3" v-else-if="product.vipLevel == 3"></div>
        <div class="vipIcon vipLevel4" v-else-if="product.vipLevel == 4"></div>
        <div class="vipIcon vipLevel5" v-else-if="product.vipLevel == 5"></div>
        <div class="vipIcon vipLevel6" v-else-if="product.vipLevel == 6"></div>
        <div class="vipIcon vipLevel7" v-else-if="product.vipLevel == 7"></div>
        <div class="vipIcon vipLevel1" v-else></div>
        <div class="descBox">
          <div class="watchCount">
            每日观影次数
            <span>{{ product.totalWatchCount }}次</span>
          </div>
          <div class="depositRebate">
            返利比例
            <span>{{ product.depositRebate }}%</span>
          </div>
        </div>
      </div>
      <!-- 广告轮播 -->
      <AdvSwiper :advList="advList" class="advSwiper" v-if="advList.length > 0" />
      <div class="openNow" @click="showPay(true)">立即开通</div>
    </div>
    <RechargeBox ref="rechargeBox" :isRecharge="isRecharge" @closePay="showPay" />
  </div>
</template>

<script>
import { queryVipList } from '@/api/mine';
import { mapGetters } from 'vuex';
import { Toast } from 'vant';
import { AdType, getAdItem } from '@/utils/getConfig';
import Vip from './Vip/index.vue';
import RechargeBox from '@/components/RechargeBox';
import AdvSwiper from '@/components/AdvSwiper';

export default {
  name: 'MemberCentre',
  components: {
    Vip,
    RechargeBox,
    AdvSwiper,
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  data() {
    return {
      currentTab: 0, // tab选中的下标
      active: 0, // tab选中下标
      isRecharge: false, // 充值弹窗
      dcInfo: '', // 代充信息
      productID: '', // 商品ID
      graphicsId: '',
      graphicsTitle: '',
      vipList: [], // vip列表
      svipList: [], // svip列表
      privileges: [], // 特权列表
      rechargeList: [], // 充值列表
      advList: [], // 广告列表
      product: '', // 当前选中的vip
    };
  },
  created() {
    this.advList = getAdItem(AdType.MEMBER_CENTER_ADV); // 获取轮播广告数据
    if (this.$route.query.t == 'vip') {
      this.active = 0;
    } else {
      this.active = 1;
    }
    this.queryVipList();
  },
  methods: {
    changeTabIndex(index) {
      this.currentTab = index;
    },
    // 查询会员卡列表
    async queryVipList() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(queryVipList);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          res.data.list.forEach((i) => {
            if (i.position === '会员卡') {
              let arr = [...i.list];
              let cid = this.$route.query.cid;
              if (cid) {
                arr.forEach((item, index) => {
                  if (item.productID == cid) {
                    let newArr = arr.splice(index);
                    this.vipList = [...newArr, ...arr];
                  }
                });
              } else {
                this.vipList = [...arr];
              }
            } else if (i.position === '超级会员卡') {
              let arr = [...i.list];
              let cid = this.$route.query.cid;
              if (cid) {
                arr.forEach((item, index) => {
                  if (item.productID == cid) {
                    let newArr = arr.splice(index);
                    this.svipList = [...newArr, ...arr];
                  }
                });
              } else {
                this.svipList = [...arr];
              }
            }
          });
          this.dcInfo = res.data.daichong;
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('获取会员卡信息失败，请退出重试');
      }
    },
    showPrivilege(obj) {
      this.product = obj.product;
      obj.payData.dcInfo = this.dcInfo;
      this.rechargeList = obj.payData.rechargeList;
      this.$refs.rechargeBox.changItem(obj.payData);
    },
    showPay(flag) {
      if (flag && !this.rechargeList.length) return Toast('该金额暂无充值方式');
      this.isRecharge = flag;
    },
  },
  beforeRouteEnter(to, from, next) {
    //视频跳转过来
    if (from.name == 'CommunityVideo' || from.name == 'HorizontalVideo' || from.name == 'ShortVideo') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = vm.$store.getters.currentVideoObj['id'];
        vm.$refs.rechargeBox.graphicsTitle = vm.$store.getters.currentVideoObj['title'];
      });
    } else if (from.name == 'ComRecommend' || from.name == 'Announcement') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'banner';
      });
    } else if (from.name == 'TaskHall') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'signIn';
      });
    } else if (from.name == 'PostDetails') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'exceptional';
      });
    } else {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'user';
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.memberCentre {
  height: 100%;
  overflow-y: auto;
  .header {
    height: 44px;
    padding: 0 14px;
    .back {
      > svg {
        height: 19px;
        width: 19px;
      }
    }
    .title {
      color: rgb(0, 0, 0);
      font-size: 14px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      font-weight: 600;
    }
    .right-btn {
      color: rgb(102, 102, 102);
      font-size: 12px;
      font-weight: 500;
    }

    .rightIcon {
      width: 20px;
      height: 20px;
    }
  }
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 10px;
    .privilegeBox {
      padding: 13px 17px;
      background-color: rgba(248, 248, 249, 0.7);
      margin: 10px 20px 0;
      box-sizing: border-box;
      border-radius: 10px;
      .vipIcon {
        width: 90.5px;
        height: 21px;
      }
      .vipLevel1 {
        background: url('./../../../assets/png/vipLevel1.png');
        background-size: 100% 100%;
      }
      .vipLevel2 {
        background: url('./../../../assets/png/vipLevel2.png');
        background-size: 100% 100%;
      }
      .vipLevel3 {
        background: url('./../../../assets/png/vipLevel3.png');
        background-size: 100% 100%;
      }
      .vipLevel4 {
        background: url('./../../../assets/png/vipLevel4.png');
        background-size: 100% 100%;
      }
      .vipLevel5 {
        background: url('./../../../assets/png/vipLevel5.png');
        background-size: 100% 100%;
      }
      .vipLevel6 {
        background: url('./../../../assets/png/vipLevel6.png');
        background-size: 100% 100%;
      }
      .vipLevel7 {
        background: url('./../../../assets/png/vipLevel7.png');
        background-size: 100% 100%;
      }
      .descBox {
        font-size: 12px;
        font-weight: 600;
        text-align: right;
        color: rgb(102, 102, 102);
        letter-spacing: 0.24px;
        .watchCount {
          margin-bottom: 3px;
        }
        span {
          color: rgb(247, 75, 130);
          margin-left: 5px;
        }
      }
    }
    .advSwiper {
      height: 93px;
      width: 335px;
      margin: 10px auto 19px;
      /deep/ .adv-container {
        overflow: hidden;
        border-radius: 6px;
        height: 93px;
        .my-swipe {
          height: 93px;
          .adv-swiper-slide {
            height: 93px !important;
            width: 335px !important;
          }
        }
        img {
          height: 100%;
          border-radius: 6px;
        }
      }
      /deep/ img {
        height: 100%;
        object-fit: fill;
      }
    }
    .openNow {
      height: 50px;
      width: 270px;
      background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
      text-align: center;
      line-height: 50px;
      border-radius: 10px;
      color: white;
      font-size: 16px;
      margin: 27px auto;
    }
  }
}
</style>
