<template>
  <div class="vip">
    <Swiper class="vipSwiper" ref="vipSwiper" :options="swiperOption">
      <SwiperSlide class="swiperSlide" v-for="item in vipList" :key="item.productID">
        <ImgDecypt class="vipBg" :src="item.bgImg" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import ImgDecypt from '@/components/ImgDecypt';

export default {
  name: 'Vip',
  components: {
    Swiper,
    SwiperSlide,
    ImgDecypt,
  },
  props: ['vipList'],
  data() {
    let _this = this;
    return {
      // 轮播配置
      swiperOption: {
        direction: 'horizontal', // 水平切换选项
        loop: true, // 循环模式
        slidesPerView: 'auto', // 默认一个屏幕显示几张图
        centeredSlides: true, // 每屏，居中显示
        // observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        // observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        on: {
          slideChangeTransitionEnd: () => {
            this.activeIndex = this.$refs.vipSwiper.$swiper ? this.$refs.vipSwiper.$swiper.realIndex : 0;
            this.selectedItem = this.vipList[this.activeIndex];
            this.changItem();
            // if (this.$refs.vipSwiper.$swiper) {
            //   this.$refs.vipSwiper.$swiper.slideToLoop(this.$refs.vipSwiper.$swiper.realIndex, 0, false);
            // }
          },
          // click: function () {
          //   _this.handleInvite(this);
          // },
        },
      },
      activeIndex: 0, // 选中会员卡下标
      selectedItem: {}, // 选中
    };
  },
  created() {
    this.selectedItem = this.vipList[0];
    this.changItem();
  },
  methods: {
    // 点击会员卡
    handleInvite(vm) {
      this.activeIndex = this.$refs.vipSwiper.$swiper ? this.$refs.vipSwiper.$swiper.clickedIndex : 0;
      this.selectedItem = this.vipList[this.activeIndex];
      this.changItem();
      // this.$refs.vipSwiper.$swiper.slideTo(vm.clickedIndex);
      // let index = vm.clickedIndex - vm.activeIndex + vm.realIndex;
      // if (index === this.vipList.length) {
      //   index = 0;
      // } else if (index === this.vipList.length + 1) {
      //   index = 1;
      // } else if (index === this.vipList.length + 2) {
      //   index = 2;
      // }
    },
    // 选中会员卡
    changItem() {
      let payData = {
        rechargeList: this.selectedItem.rchgType, // 支付方式列表
        // dcInfo: this.dcInfo, // 代充信息
        popType: 'vip', // 弹窗类型
        productType: this.selectedItem.productType, // 商品类型
        productID: this.selectedItem.productID, // 商品ID
        dcType: 1, // 代充商品类型
        buyType: 4, // 商品类型  1-是金币 2-是游戏币 3-是果币 4-是商品
        discountedPrice: this.selectedItem.discountedPrice, // 充值金额
        isAmountPay: this.selectedItem.isAmountPay, // 是否支持金币兑换
      };
      this.$emit('showPrivilege', {
        product: this.selectedItem,
        payData: payData,
      }); // 给父组件特权
    },
  },
};
</script>
<style lang="scss" scoped>
.vip {
  overflow-x: auto;
  display: flex;
  align-items: center;
  .vipSwiper {
    height: 157px;
    .swiperSlide {
      width: 319px;
      margin: 0 6px;
      .vipBg {
        width: 100%;
        height: 157px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        transform: translateZ(100deg);
        border-radius: 10px;
      }
    }
  }
}
</style>
